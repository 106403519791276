
//Font family
export const FontFamily = 'GT-Walsheim-Regular,sans-serif;'
export const FontFamilyText = 'GT-Walsheim-Regular,sans-serif;'
export const FontFamilyTags = 'GT-Walsheim-Thin,sans-serif;'

//font sizes
export const MainTitleFont = '32px';
export const ArticleTitleFont = '24px';
export const SubTitleFont = '18px';
export const TagsFont = '14px';
export const TextFont = '24px';

//text colors
export const TextBgColor = '#323232'
