import Grid from '@material-ui/core/Grid';
import styled from 'styled-components';


const Wrapper = styled.div`
  margin: 0 5% 0 5%;
   @media (max-width: 768px) {
    margin: auto 8% auto 8%;
  padding: 0px;
  }
`;



const GridContainer = styled(Grid)`
  margin-top: 50px;

   @media (max-width: 768px) {

  }
`;




export { GridContainer, Wrapper };
