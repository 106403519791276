import Save from "@material-ui/icons/SaveAlt"
import { navigate } from "gatsby"
import React from "react"
import { useDispatch, useSelector } from "react-redux"
import {
  getDatafromFirestore,
  saveArticleOnFirebase,
} from "../redux/firebaseAction"
import { ButtonStyled } from "../components/styles/styles"
import { grabSlugFromString } from "../utils/utils"

const SaveOnFirebase = ({ title, image, slug }) => {
  const cleaned_slug = grabSlugFromString(slug)

  const { Error, isLoggedin, user } = useSelector(state => ({
    ...state.mainReducer,
    ...state.mainReducer.user.user,
  }))
  const dispatch = useDispatch()
  const getData = () => dispatch(getDatafromFirestore(user.user.email))
  const setonFirestore = () =>
    dispatch(
      saveArticleOnFirebase(user.user.email, title, image, slug, cleaned_slug)
    )
  const RedirectAfterSignUp = () => {
    if (isLoggedin && !Error) {
      setonFirestore()
      getData()
      navigate("/dashboard")
    } else navigate("/dashboard")
  }

  return (
    <ButtonStyled
      aria-label="save for later"
      onClick={RedirectAfterSignUp}
      size="small"
      color="primary"
    >
      <Save />
    </ButtonStyled>
  )
}
export default SaveOnFirebase
