/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import PropTypes from "prop-types"
import React from "react"
import { Wrapper } from "./containers/containers"
import Footer from "./footer"
import Header from "./header"
import "./layout.css"

const Layout = ({ children }) => (
  <>
    <div
      className="root"
      style={{
        // margin: `0 5% 0 5%`,
        maxWidth: 1200,
        margin: "auto",
        // padding: `0px 1.0875rem 1.45rem`,
        // paddingTop: 0,
      }}
    >
      <Header />
      <Wrapper>
        <main
          style={{
            margin: `0 auto`,
            // maxWidth: 960,
            minHeight: "100vh",
            // padding: `0px 1.0875rem 1.45rem`,
            paddingTop: 0,
          }}
        >
          {children}
        </main>
      </Wrapper>
    </div>
    <Footer />
  </>
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
