import CardActions from "@material-ui/core/CardActions"
import CardContent from "@material-ui/core/CardContent"
import CardMedia from "@material-ui/core/CardMedia"
import LocalOffer from "@material-ui/icons/LocalOffer"
import { Link } from "gatsby"
import Img from "gatsby-image"
import React from "react"
import SaveOnFirebase from "../components/saveOnFirebase"
import {
  FlatCard,
  ShareStyled,
  SubTitle,
  TagContainer,
  TagsStyled,
  Title,
} from "../components/styles/styles"
import { CategoryStyled } from "./styles/styles"

const _ = require("lodash")

export default function BlogCard(props) {
  const {
    frontmatter: { title, tags, subtitle },
    fields: { slug },
  } = props.data.node

  const image =
    props.data.node.frontmatter.featuredImage?.childImageSharp?.fixed ??
    "https://miaggrego.vinitalytour.com/wp-content/uploads/2020/02/image-placeholder.png"
  const { style } = props
  const __tags = tags?.split(",")
  // const tags_ = _.uniq(_.flattenDeep(__tags))

  console.log("BlogCard", props, image)
  return (
    <FlatCard>
      <CardMedia style={{ height: style.height }} title={title} src={title}>
        {image && (
          <Img
            imgStyle={{ objectFit: style.image }}
            style={{ width: "100%", height: "inherit" }}
            fixed={image}
          />
        )}
      </CardMedia>
      <CardContent>
        {props.data.node.frontmatter.category && (
          <CategoryStyled>
            {props.data.node.frontmatter.category}{" "}
          </CategoryStyled>
        )}
        <TagContainer>
          {__tags?.map?.((tag, index) => (
            <Link key={index} to={`/tags/${_.kebabCase(tag)}/`}>
              <TagsStyled>
                <span>
                  <LocalOffer />
                  {tag}
                </span>
              </TagsStyled>
            </Link>
          ))}
        </TagContainer>
        <Link to={slug}>
          <Title
            marginBottom="0px"
            dangerouslySetInnerHTML={{ __html: title }}
          />
        </Link>
        <SubTitle>{subtitle}</SubTitle>
      </CardContent>
      <CardActions style={{ justifyContent: "space-between" }}>
        <SaveOnFirebase image={image.src} title={title} slug={slug} />
        <Link to={slug}>
          <ShareStyled>...</ShareStyled>
        </Link>
      </CardActions>
    </FlatCard>
  )
}
