import React from "react"
import Facebook from "../assets/images/facebook.svg"
import Insta from "../assets/images/insta.svg"
import Twitter from "../assets/images/twitter.svg"
import Grid from "@material-ui/core/Grid"
import TextField from "@material-ui/core/TextField"
import Button from "@material-ui/core/Button"
import Container from "@material-ui/core/Container"
import { Link} from "gatsby"
import { FooterGrid, NavBar } from "./styles/styles"




const Footer = props => {
  // const classes = NavBar();

  return (
    <>
      <FooterGrid>
        <Container maxWidth="md">
          <Grid
            justify="space-between"
            alignItems="flex-start"
            className="links"
            container
            lg={12}
          >
            <Grid item lg={2} xs={6} sm={4}>
              <ul>
                <li>
                  <h5>Company</h5>
                </li>
                <li>
                  <Link to={"/tags/"}>About Us</Link>
                </li>
                <li>
                  <Link to={"/tags/"}>Our Team</Link>
                </li>
                <li>
                  <Link to={"/tags/"}>Events</Link>
                </li>
                <li>
                  <Link to={"/tags/"}>In the news</Link>
                </li>
                <li>
                  <Link to={"/tags/"}>Jobs</Link>
                </li>
              </ul>
            </Grid>
            <Grid item lg={2} xs={6} sm={4}>
              <ul>
                <li>
                  <h5>Explore</h5>
                </li>
                <li>
                  <Link to={"/tags/"}>Recipes</Link>
                </li>
                <li>
                  <Link to={"/tags/"}>Wines</Link>
                </li>
                <li>
                  <Link to={"/tags/"}>Lists</Link>
                </li>
                <li>
                  <Link to={"/tags/"}>Blogs</Link>
                </li>
                <li>
                  <Link to={"/tags/"}>Categories</Link>
                </li>
              </ul>
            </Grid>
            <Grid item lg={2} xs={6} sm={4}>
              <ul>
                <li>
                  <h5>Company</h5>
                </li>
                <li>
                  <Link to={"/tags/"}>About Us</Link>
                </li>
                <li>
                  <Link to={"/tags/"}>Our Team</Link>
                </li>
                <li>
                  <Link to={"/tags/"}>Events</Link>
                </li>
                <li>
                  <Link to={"/tags/"}>In the news</Link>
                </li>
                <li>
                  <Link to={"/tags/"}>Jobs</Link>
                </li>
              </ul>
            </Grid>
            <Grid item lg={4} xs={12} sm={4}>
              <h4>miaggrego newsletter</h4>
              <p>
                Subscribe to our newsletter to get our best tips in your inbox
                daily
              </p>
              <TextField
                id="outlined-size-small"
                size="small"
                defaultValue=" "
                variant="outlined"
                onChange={e => console.log(e.target.value)}
              />
              <Button
                variant="outlined"
                type="submit"
                onClick={() => console.log("")}
              >
                Sign Up
              </Button>
            </Grid>
          </Grid>
        </Container>
      </FooterGrid>
      <NavBar position="static">
        <a className="logo" href="#" title=" ©miaggrego" rel="home">
          2020 © miaggrego
        </a>
        <div id="footer-links">
          <a href="#">Terms</a>
          <span>|</span>
          <a href="#"> Privacy</a>
          <span>|</span>
          <a href="#"> Code of Conduct</a>
          <span>|</span>
          <a href="#"> Privacy Policy</a>
          <a className="twitter" href="#">
            <span className="screen-reader-only">
              <img src={Insta} />
            </span>
          </a>
          <a className="instagram" href="#">
            <span className="screen-reader-only">
              <img src={Twitter} />
            </span>
          </a>
          <a className="instagram" href="#">
            <span className="screen-reader-only">
              <img src={Facebook} />
            </span>
          </a>
        </div>
      </NavBar>
    </>
  )
}

export default Footer




